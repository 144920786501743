import {Component} from '@angular/core';
import {Observable} from 'rxjs';

import {Breadcrumb, BreadcrumbService} from '../../services/breadcrumb.service';

@Component({
    selector: 'mgcn-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    standalone: false
})
export class BreadcrumbComponent {
    breadcrumbs$: Observable<Breadcrumb[]>;

    constructor(
        private breadcrumbService: BreadcrumbService
    ) {
        this.breadcrumbs$ = breadcrumbService.breadcrumbs$;
    }

    back(): void {
        this.breadcrumbService.back();
    }
}
