import {Pipe, PipeTransform} from '@angular/core';

/**
 * This pipe returns the firstname and lastname from a User object (ex: John Doe).
 */
@Pipe({
    name: 'displayName',
    standalone: false
})
export class DisplayNamePipe implements PipeTransform {
    transform(user?: {firstname?: string, lastname?: string} | null | undefined): string {
        return user?.firstname + ' ' + user?.lastname;
    }
}
