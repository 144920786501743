import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'noHtml',
    standalone: false
})
export class NoHtmlPipe implements PipeTransform {

    transform(value: string): string {
        if (!value) {
            return value;
        }

        return value.replace(/<[^>]*>/g, '');
    }

}
