import {SocialAuthService} from '@abacritt/angularx-social-login';
import {ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, filter, tap} from 'rxjs';
import {SubSink} from 'subsink';

import {AuthStore} from '../../services/auth.store';

@Component({
    selector: 'auth-google-button',
    templateUrl: './google-button.component.html',
    styleUrls: ['./google-button.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class GoogleButtonComponent implements OnInit, OnDestroy {
    requesting$: Observable<boolean> = this.authStore.selectRequesting$;

    private subs = new SubSink();

    constructor(
        private socialAuthService: SocialAuthService,
        private authStore: AuthStore
        // eslint-disable-next-line no-empty-function
    ) {}

    ngOnInit(): void {
        this.subs.sink = this.socialAuthService.authState
            .pipe(
                filter(user => null !== user),
                tap(user => this.authStore.loginWithGoogle(user))
            )
            .subscribe();
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
}
