import {Pipe, PipeTransform, inject} from '@angular/core';
import {CodeFormatterService, Versionable, VersionableType} from '@versionable/data';
import {Observable} from 'rxjs';

@Pipe({
    name: 'code',
    standalone: false
})
export class CodePipe implements PipeTransform {
    #codeFormatter = inject(CodeFormatterService);

    transform(versionable: Pick<Versionable, 'id' | 'code'>, type: VersionableType): Observable<string> {
        return this.#codeFormatter.format(versionable, type);
    }
}
