import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatOption, MatSelect} from '@angular/material/select';
import {IdType} from '@axiocode/entity';
import {InformationSystem, InformationSystemStore} from '@information-system/data';
import {TranslateModule} from '@ngx-translate/core';
import {OrganizationStore} from '@organization/data';
import {AppRoutes} from '@routing';
import {DrawerService} from '@ui/drawer';
import {PermissionService, keepOnlyInformationSystemWithPermission} from '@user/security';
import {isDefined, modelsEqualityById} from '@utils';
import {map, switchMap} from 'rxjs';

@Component({
    selector: 'sidebar-information-system-switcher',
    template: `
    <div class="row">
        <div class="switcher-select" *ngIf="filteredIS$ | async as ISs">
            <mat-form-field>
                <mat-label>{{ 'INFORMATION_SYSTEM.INFORMATION_SYSTEM' | translate }}</mat-label>
                <mat-select [compareWith]="compare" [(value)]="currentIS">
                    <mat-option
                        *ngFor="let IS of ISs"
                        (click)="changeIS(IS)"
                        [value]="IS">
                        {{ IS.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        MatFormFieldModule,
        MatSelect,
        MatOption,
        TranslateModule,
        CommonModule,
        TranslateModule
    ]
})
export class InformationSystemSwitcherComponent {

    @Input() currentIS?: InformationSystem;

    filteredIS$ = this.organizationStore.selectSelectedEntity$.pipe(
        isDefined(),
        switchMap(currentOrganization => this.ISStore.selectAllByOrganization$(currentOrganization)),
        keepOnlyInformationSystemWithPermission('read', this.permissionService),
        map(ISs => ISs.sort((a, b) => a.name.localeCompare(b.name))),
    );

    compare = modelsEqualityById;

    constructor(
        public routes: AppRoutes,
        private ISStore: InformationSystemStore,
        private organizationStore: OrganizationStore,
        private drawerService: DrawerService,
        private permissionService: PermissionService,
    ) {
        this.ISStore.refreshAll();
    }

    changeIS(is: {id: IdType}): void {
        this.drawerService.closeDrawer().then(() => this.routes.informationSystem.details(is.id).navigate());
    }
}
