import {CommonModule} from '@angular/common';
import {AfterViewInit, ChangeDetectionStrategy, Component, signal, viewChild} from '@angular/core';
import {Branch} from '@branch/data';
import {TranslateModule} from '@ngx-translate/core';
import {DialogComponent} from '@ui/dialog';
import {MaterialModule} from '@ui/material';

import {BranchFormComponent} from '../branch-form/branch-form.component';

@Component({
    selector: 'branch-dialog',
    imports: [CommonModule, MaterialModule, TranslateModule, BranchFormComponent],
    templateUrl: './branch-dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BranchDialogComponent extends DialogComponent implements AfterViewInit {
    branchForm = viewChild.required(BranchFormComponent);
    invalid = signal(true);
    saving = signal(false);

    ngAfterViewInit(): void {
        super.addActionShortcuts(true);
    }

    submitted(next: Branch): void {
        super.removeActionShortcuts();
        this.dialogAccepted.next(next);
    }

    public override handleValidationForm(): void {
        this.branchForm().validate();
    }
}
