import {ActorStore} from '@actor/data';
import {ChangeDetectionStrategy, Component, ErrorHandler, inject, Inject} from '@angular/core';
import {toSignal} from '@angular/core/rxjs-interop';
import {NavigationEnd, Router} from '@angular/router';
import {AppComponentStore} from '@app-component/data';
import {ApplicationStore} from '@application/data';
import {ErrorHandlerService} from '@axiocode/error-handler';
import {BranchStore} from '@branch/data';
import {DataModelStore} from '@data-model/data';
import {FeatureStore} from '@feature/data';
import {FormStore} from '@form/data';
import {FunctionalRequirementStore} from '@functional-requirement/data';
import {GlossaryTermStore} from '@glossary-term/data';
import {InformationSystem, InformationSystemStore} from '@information-system/data';
import {NonFunctionalRequirementStore} from '@non-functional-requirement/data';
import {PageStore} from '@page/data';
import {AppRoutes} from '@routing';
import {TableStore} from '@table/data';
import {NavigationPlanService} from '@ui/navigation-plan';
import {ScrollingService} from '@ui/scrolling';
import {UseCaseStore} from '@use-case/data';
import {AuthStore} from '@user/auth';
import {UserStore} from '@user/data';
import {combineLatest, distinctUntilChanged, filter, map, switchMap, startWith} from 'rxjs';

@Component({
    selector: 'sidebar-sidebar',
    templateUrl: './sidebar.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class SidebarComponent {
    /** InformationSystem details */
    currentInformationSystem$ = this.informationSystemStore.selectSelectedEntity$;
    applications$ = this.currentInformationSystem$.pipe(
        filter((is): is is InformationSystem => undefined !== is),
        switchMap(is => this.applicationStore.selectApplicationsByIS$(is))
    );

    currentApplication$ = this.applicationStore.selectSelectedEntity$;

    // Peut être à refaire / réutiliser d'une autre manière => copier coller de navigation plan pour la condition sur l'afficahge de la navigation
    #planService = inject(NavigationPlanService);
    #scrollingService = inject(ScrollingService);
    entries$ = combineLatest([this.#planService.entries, this.#scrollingService.scrolling.pipe(startWith(0))]).pipe(
        map(([entries, _]) => entries.map(entry => ({...entry, visible: this.#scrollingService.isElementVisible(entry.element)}))),
        distinctUntilChanged(),
    );

    vm$ = combineLatest([
        this.ISStore.selectSelectedEntity$,
        this.userStore.selectCurrentUser$,
        this.applicationStore.selectSelectedEntity$,
        this.currentInformationSystem$,
        this.applications$,
        this.branchStore.selectCurrentBranch$,
        this.actorStore.selectActorsForCurrentIS$,
        this.dataModelStore.selectDataModelsForCurrentIS$,
        this.glossaryTermStore.selectTermsForCurrentIS$,
        this.featureStore.selectFeaturesForCurrentIS$,
        this.featureStore.selectFeaturesForCurrentApplication$,
        this.functionalRequirementStore.selectFunctionalRequirementsForCurrentApplication$,
        this.nonFunctionalRequirementStore.selectNonFunctionalRequirementsForCurrentApplication$,
        this.pageStore.selectPagesForCurrentApplication$,
        this.useCaseStore.selectUseCasesForCurrentApplication$,
        this.formStore.selectFormsForCurrentApplication$,
        this.tableStore.selectTablesForCurrentApplication$,
        this.appComponentStore.selectAppComponentsForCurrentApplication$,
        this.authStore.selectIsLoggedIn$,
    ], (currentIS, currentUser, currentApplication, informationSystem, applications, currentBranch, actors, dataModels, terms, featuresIS, featuresApp, funcreqs, nonfuncreqs, pages, usecases, forms, tables, appcomponents, isLoggedIn) => ({
        currentIS, currentUser, currentApplication, informationSystem, applications, currentBranch, actors, dataModels, terms, featuresIS, featuresApp, funcreqs, nonfuncreqs, pages, usecases, forms, tables, appcomponents, isLoggedIn
    }));

    currentUrl = toSignal(this.router.events.pipe(
        filter((event): event is NavigationEnd => event instanceof NavigationEnd),
        map(event => event.url)
    ));

    constructor(
        private informationSystemStore: InformationSystemStore,
        private applicationStore: ApplicationStore,
        private branchStore: BranchStore,
        private actorStore: ActorStore,
        private dataModelStore: DataModelStore,
        private glossaryTermStore: GlossaryTermStore,
        private featureStore: FeatureStore,

        private appComponentStore: AppComponentStore,
        private formStore: FormStore,
        private functionalRequirementStore: FunctionalRequirementStore,
        private nonFunctionalRequirementStore: NonFunctionalRequirementStore,
        private pageStore: PageStore,
        private useCaseStore: UseCaseStore,
        private tableStore: TableStore,

        private userStore: UserStore,
        private ISStore: InformationSystemStore,
        private authStore: AuthStore,

        @Inject(ErrorHandler) private errorHandler: ErrorHandlerService,
        public routes: AppRoutes,
        public router: Router,
        // eslint-disable-next-line no-empty-function
    ) {
    }

    checkIsActive(route: string): boolean {
        let url = this.currentUrl();
        if (undefined === url) {
            url = this.router.url;
        }

        return route === url;
    }
}
