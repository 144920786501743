import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, RedirectCommand, ResolveFn, Router, RouterStateSnapshot} from '@angular/router';
import {OrganizationStore} from '@organization/data';
import {AppRoutes} from '@routing';
import {catchError, map, of, tap} from 'rxjs';

import {Application} from '../models/application.model';
import {ApplicationProvider} from '../services/application.provider';
import {ApplicationStore} from '../services/application.store';

/**
 * This resolver fetches the application from the path parameter "applicationId" and sets the application as the current one.
 */
export const applicationResolver: ResolveFn<Application | undefined | null> = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const applicationProvider = inject(ApplicationProvider);
    const applicationStore = inject(ApplicationStore);
    const id = route.paramMap.get('applicationId');
    const organizationStore = inject(OrganizationStore);
    const routes = inject(AppRoutes);
    const router = inject(Router);

    if (null === id) {
        return null;
    }

    organizationStore.findAll();

    return applicationProvider.findOne$(id).pipe(
        tap(application => {
            applicationStore.upsertOne(application);
            applicationStore.setSelected(application ?? undefined);
        }),
        map(application => {
            const isId = route.paramMap.get('informationSystemId');
            const isDrawer = route.parent?.url.filter(e => 'preview' === e.path).length;
            // Redirection in case of an old URL starting with "/application/XXX/..."
            if (!isId && !isDrawer) {
                let url = `/is/${application.informationSystem.id}${state.url}`;

                return new RedirectCommand(router.parseUrl(url));
            }

            return application;
        }),
        catchError(() => {
            routes.error404.navigate({skipLocationChange: true});

            return of(null);
        })
    );
};
