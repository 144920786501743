import {Component, Input} from '@angular/core';

import {ActionButtonComponent} from './action-button.component';

@Component({
    selector: 'ui-delete-button',
    templateUrl: './action-button.component.html',
    standalone: false
})
export class DeleteButtonComponent extends ActionButtonComponent {

    @Input() override color = 'warn';
    @Input() override icon = 'delete';

}
