import {Component, Input} from '@angular/core';

import {ActionButtonComponent} from './action-button.component';

@Component({
    selector: 'ui-list-button',
    templateUrl: './action-button.component.html',
    standalone: false
})
export class ListButtonComponent extends ActionButtonComponent {

    @Input() override color = 'primary';
    @Input() override icon = 'list';
    @Input() override buttonType: 'normal' | 'stroked' | 'raised' | 'icon' | 'menu-item' = 'raised';

}
