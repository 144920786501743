import {Component, Input} from '@angular/core';

import {ActionButtonComponent} from './action-button.component';

@Component({
    selector: 'ui-view-button',
    templateUrl: './action-button.component.html',
    standalone: false
})
export class ViewButtonComponent extends ActionButtonComponent {

    @Input() override color = 'accent';
    @Input() override icon = 'visibility';

}
