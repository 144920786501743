import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ErrorHandler, Inject, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormGroup, UntypedFormArray} from '@angular/forms';
import {MatChipInputEvent} from '@angular/material/chips';
import {ApplicationStore} from '@application/data';
import {ErrorHandlerService} from '@axiocode/error-handler';
import {ConfigurationStore} from '@configuration';
import {DataModel, DataModelAttribute, DataModelLocalStore, DataModelStore, formatAPICreate, formatAPIUpdate} from '@data-model/data';
import {GlossaryTermStore} from '@glossary-term/data';
import {InformationSystem, InformationSystemStore} from '@information-system/data';
import {MentionConversionService} from '@mention/data';
import {filterOnName, filterRequirementWithCode, firstOrNull, isDefined, modelsEqualityById} from '@utils';
import {BehaviorSubject, Subject, combineLatest, delay, map, switchMap, take, tap} from 'rxjs';
import {SubSink} from 'subsink';

import {DataModelType} from '../../forms/data-model.type';

@Component({
    selector: 'data-model-form',
    templateUrl: './data-model-form.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class DataModelFormComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {

    @Output() submitted: Subject<DataModel> = new Subject<DataModel>();
    @Output() isInvalid: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    @Input() informationSystem?: InformationSystem = undefined;
    @Input() dataModel?: DataModel = undefined;
    @Input() disableGlossaryTerm!: boolean;
    @Input() form!: FormGroup;
    @Input() formType = new DataModelType();

    nextCodeAvailable$ = this.store.selectNextCodeAvailable$.pipe(
        // We don't want to update the form if the user has already entered a value.
        take(1),
        delay(1),
        tap(value => {
            if (value && !this.dataModel?.id) {
                this.form.patchValue({code: value});
            }
        })
    );

    private subs = new SubSink();

    isCreateMode = false;
    compare = modelsEqualityById;
    filter = filterRequirementWithCode;
    filterName = filterOnName;
    prefix = '';
    dataModelsInherits: DataModel[] = [];
    separatorKeysCodes: number[] = [ENTER, COMMA];

    hasToAttachGlossaryTerm = false;
    generatePage = false;
    allGlossaryTerms$ = this.glossaryTermStore.selectTermsForCurrentIS$.pipe(
        map(glossaryTerms => glossaryTerms.filter(glossaryTerm => glossaryTerm.dataModel?.id === this.dataModel?.id || !glossaryTerm.dataModel)),
        map(glossaryTerms => glossaryTerms.sort((a, b) => a.word.localeCompare(b.word))),
    );
    filteredApplications$ = this.ISStore.selectSelectedEntity$.pipe(
        isDefined(),
        switchMap(currentIS => this.applicationStore.selectApplicationsByIS$(currentIS)),
        map(applications => applications.sort((a, b) => a.name.localeCompare(b.name))),
    );

    types?: string[] = [];

    vm$ = combineLatest([
        this.localStore.selectVersionableState$,
    ]).pipe(
        map(([state]) => ({state})));

    constructor(
        private localStore: DataModelLocalStore,
        private store: DataModelStore,
        private glossaryTermStore: GlossaryTermStore,
        private ISStore: InformationSystemStore,
        private applicationStore: ApplicationStore,
        private configurationStore: ConfigurationStore,
        @Inject(ErrorHandler) private errorHandler: ErrorHandlerService,
        private mentionConversionService: MentionConversionService,
        private cd: ChangeDetectorRef,
    ) {
        this.form = this.formType.getForm();
    }

    /** @ignore */
    ngOnInit(): void {
        this.store.findAll();
        this.glossaryTermStore.findAll();
        this.applicationStore.findAll();
        this.configurationStore.load();

        this.subs.sink = this.nextCodeAvailable$.subscribe();

        this.subs.sink = this.store.updated.subscribe(change => {
            const entity = firstOrNull(change.entities);
            if (entity) {
                this.submitted.next(entity);
                this.localStore.changeSaveState(false);
            }
        });

        this.subs.sink = this.store.created.subscribe(change => {
            const entity = firstOrNull(change.entities);
            if (entity) {
                this.submitted.next(entity);
                this.localStore.changeSaveState(false);
            }
        });

        this.subs.sink = this.store.error.subscribe(error => {
            this.localStore.changeSaveState(false);
            let apiFormError = this.errorHandler.handleError(error);
            if (apiFormError?.formErrors && this.form) {
                this.errorHandler.applyFormErrors(apiFormError.formErrors, this.form);
                this.cd.detectChanges();
            }
        });

        this.subs.sink = this.store.selectAll$.pipe(
            take(1),
            map(allDataModels => allDataModels.filter(
                value => (undefined === this.dataModel || value.inherits?.id !== this.dataModel.id && value.id !== this.dataModel.id) && 'enum' !== value.type
            ))
        ).subscribe(dataModels => this.dataModelsInherits = dataModels);

        if (this.dataModel) {
            this.setValue(this.dataModel);
            this.isCreateMode = false;
        } else {
            this.isCreateMode = true;
        }

        this.subs.sink = this.configurationStore.selectConfigurations$.pipe(
            tap(value => this.prefix = value?.dataModelCodePrefix as string),
            tap(value => this.types = value?.dataModelTypes),
        ).subscribe();

        this.form.get('type')?.valueChanges.subscribe({
            next: (type => {
                if ('enum' === type) {
                    const dataModelRelationShips = this.form.get('dataModelRelationships') as UntypedFormArray;
                    dataModelRelationShips.clear();
                    this.form.get('inherits')?.patchValue(null);
                    const dataModelAttributes = this.form.get('dataModelAttributes') as UntypedFormArray;
                    for (let dataModelAttribute of dataModelAttributes.controls) {
                        dataModelAttribute.get('type')?.setValue('string');
                    }
                }
            })
        });

        // Fetch all data models for inheritance (except those which already inherit from the current model and enums)
        this.subs.sink = this.store.selectAll$.pipe(
            map(allDataModels => allDataModels.filter(
                value => (undefined === this.dataModel || value.inherits?.id !== this.dataModel.id && value.id !== this.dataModel.id) && 'enum' !== value.type
            )),
            map(dataModels => dataModels.sort((a, b) => a.name.localeCompare(b.name)))
        ).subscribe(dataModels => this.dataModelsInherits = dataModels);
    }

    /** @ignore */
    ngAfterViewInit(): void {
        // Invalid changes
        this.subs.sink = this.form.statusChanges.subscribe(() => this.isInvalid.next(this.form.invalid));
    }

    /** @ignore */
    ngOnChanges(changes: SimpleChanges): void {
        if (changes['dataModel'] && this.dataModel) {
            if ('edition' !== this.localStore.state().versionableState) {
                this.setValue(this.dataModel);
            }
            // If the new entity is different than the previous one, we change the state to "view".
            if (!this.localStore.state().force && changes['dataModel'].previousValue && changes['dataModel'].currentValue?.id !== changes['dataModel'].previousValue?.id) {
                this.localStore.changeVersionableState('view');
            }
        }
    }

    /** @ignore */
    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    validate(): void {
        if (this.informationSystem && this.form.valid) {
            const data = this.getValue();
            if (this.isCreateMode) {
                this.store.create(formatAPICreate(data));
            } else {
                this.store.update({data: formatAPIUpdate(data)});
            }
            this.localStore.changeSaveState(true);
        }
    }

    setValue(model: DataModel): void {
        this.formType.bind(model);
        this.mentionConversionService.convertDescriptionMention(model.description).pipe(take(1)).subscribe(
            value => {
                if (!this.form.get('description')?.touched) {
                    this.form.patchValue({description: value});
                }
            }
        );
    }

    getValue(): DataModel {
        let data = this.form.getRawValue();
        // Need to force the id because of the data-model.model update.
        // This change is linked to the ability of removing inheritance.
        if (data.inherits) {
            data.inherits = {
                id: data.inherits.id
            };
        }
        if (this.dataModel) {
            // Duplicate the data model attributes
            if (this.isCreateMode) {
                const attributes: DataModelAttribute[] = this.dataModel.dataModelAttributes || [];
                data = {
                    ...this.dataModel,
                    ...data,
                    dataModelAttributes: attributes.map(attribute => ({
                        name: attribute.name,
                        description: attribute.description,
                        type: attribute.type,
                        orderPosition: attribute.orderPosition,
                    }))
                };
            } else {
                data = {...this.dataModel, ...data};
            }
        }
        if (this.informationSystem) {
            data.informationSystem = this.informationSystem;
        }
        if ('enum' === data.type) {
            data.generatePage = undefined;
        }

        return data;
    }

    addDomain(event: MatChipInputEvent): void {
        // trim value
        const value = event.value ? event.value.trim() : undefined;
        if (value && value.length) {
            const control = this.form.get('functionalDomains');
            const domains: string[] = control ? this.copyTags(control.value) : [];
            // if the domain is already in the array, don't add it a second time
            const index = domains.findIndex(domain => domain === value);
            if (-1 === index) {
                domains.push(value);
                control?.setValue(domains);
                control?.updateValueAndValidity();
            }
            // Clear the input value
            event.chipInput.clear();
        }
    }

    removeDomain(domain: string): void {
        const control = this.form.get('functionalDomains');
        const domains: string[] = control ? this.copyTags(control.value) : [];
        const index = domains.indexOf(domain);
        if (index >= 0) {
            domains.splice(index, 1);
            control?.setValue(domains);
            control?.updateValueAndValidity();
        }
    }

    private copyTags(tags: string[]): string[] {
        return tags.map(tag => tag);
    }
}
