import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatOption, MatSelect} from '@angular/material/select';
import {Application, ApplicationStore} from '@application/data';
import {InformationSystemStore} from '@information-system/data';
import {TranslateModule} from '@ngx-translate/core';
import {AppRoutes} from '@routing';
import {DrawerService} from '@ui/drawer';
import {PermissionService} from '@user/security';
import {modelsEqualityById} from '@utils';
import {iif, map, of, switchMap} from 'rxjs';

@Component({
    selector: 'sidebar-application-switcher',
    template: `
    <div class="row">
        <div class="switcher-select" *ngIf="filteredApplications$ | async as applications">
                <mat-form-field>
                    <mat-label>{{ 'APPLICATION.APPLICATION' | translate }}</mat-label>
                    <mat-select [compareWith]="compare" [(value)]="currentApplication">
                        <mat-option
                            *ngFor="let application of applications"
                            (click)="changeApplication(application)"
                            [value]="application">
                            {{ application.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
        </div>
    </div>

    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        MatFormFieldModule,
        MatSelect,
        MatOption,
        TranslateModule,
        CommonModule,
        TranslateModule
    ]
})
export class ApplicationSwitcherComponent {

    @Input() currentApplication?: Application;

    filteredApplications$ = this.ISStore.selectSelectedEntity$.pipe(
        switchMap(currentIS => iif(
            () => !currentIS,
            of([] as Application[]),
            this.applicationStore.selectApplicationsByIS$(currentIS!).pipe(
                map(applications => applications.filter(application => this.permissionService.isGranted({
                    type: 'is',
                    permission: 'read',
                    target: application.informationSystem
                }))),
                map(applications => applications.sort((a, b) => a.name.localeCompare(b.name)))
            )
        )),
    );

    compare = modelsEqualityById;

    constructor(
        public routes: AppRoutes,
        private applicationStore: ApplicationStore,
        private ISStore: InformationSystemStore,
        private drawerService: DrawerService,
        private permissionService: PermissionService,
    ) {
        this.applicationStore.findAll();
    }

    changeApplication(application: Application): void {
        this.applicationStore.setSelected(application);
        this.drawerService.closeDrawer().then(() => this.routes.informationSystem.in(application.informationSystem.id).application.details(application.id).navigate());
    }
}
