import {AccountPermissionStore} from '@account-permission/data';
import {HttpErrorResponse} from '@angular/common/http';
import {Injectable, OnDestroy} from '@angular/core';
import {EntitiesChange, EntityAdapter, EntityStore, IdType} from '@axiocode/entity';
import {tapResponse} from '@ngrx/operators';
import {Organization} from '@organization/data';
import {AuthStore} from '@user/auth';
import {Observable, OperatorFunction, Subject, filter, map, switchMap, take, tap} from 'rxjs';
import {SubSink} from 'subsink';

import {UserProvider} from './user.provider';
import {UserState} from '../models/user-state.interface';
import {User} from '../models/user.model';

/**
 * This store manages the information about the current user as well as other users that might be listed
 * in the application.
 *
 * /!\ Please call UserStore.initialize() once in your application, preferably in the root app.component.
 */
@Injectable({providedIn: 'root'})
export class UserStore extends EntityStore<User, UserState> implements OnDestroy {
    // Selectors
    readonly selectCurrentUserId$ = this.select(state => state.currentUserId);
    readonly selectCurrentUser$ = this.select(
        this.selectEntities$,
        this.selectCurrentUserId$,
        (users, id) => id ? users[id] : undefined
    );
    readonly selectUserById$ = (id?: IdType) => this.select(
        this.selectEntities$,
        users => id ? users[id] : undefined
    );

    readonly setCurrentUser = this.updater((state, user: User | undefined) => ({
        ...state,
        currentUserId: user?.id,
    }));

    #created = new Subject<EntitiesChange<User>>();
    get created(): Observable<EntitiesChange<User>> {
        return this.#created.asObservable();
    }

    #updated = new Subject<EntitiesChange<User>>();
    get updated(): Observable<EntitiesChange<User>> {
        return this.#updated.asObservable();
    }

    #error = new Subject<HttpErrorResponse>();
    get error(): Observable<HttpErrorResponse> {
        return this.#error.asObservable();
    }

    // Effects
    readonly addBookmark = this.effect((application$: Observable<{id: IdType}>) => application$.pipe(
        switchMap(application => this.selectCurrentUser$.pipe(
            take(1),
            map(user => {
                if (user) {
                    return {
                        ...user,
                        applicationBookmarks: [...user.applicationBookmarks, {id: application.id}]
                    };
                }

                return user;
            })
        )),
        filter(user => undefined !== user) as OperatorFunction<User | undefined, User>,
        switchMap((user: User) => this.provider.update$(user).pipe(
            tapResponse(
                user => this.upsertOne(user),
                (error: HttpErrorResponse) => this.onError(error)
            )
        ))
    ));

    readonly removeBookmark = this.effect((application$: Observable<{id: IdType}>) => application$.pipe(
        switchMap(application => this.selectCurrentUser$.pipe(
            take(1),
            map(user => {
                if (user) {
                    let bookmarks = [...user.applicationBookmarks].filter(bookmark => bookmark.id !== application.id);

                    return {
                        ...user,
                        applicationBookmarks: bookmarks
                    };
                }

                return user;
            })
        )),
        filter(user => undefined !== user) as OperatorFunction<User | undefined, User>,
        switchMap((user: User) => this.provider.update$(user).pipe(
            tapResponse(
                user => this.upsertOne(user),
                (error: HttpErrorResponse) => this.onError(error)
            )
        ))
    ));

    readonly findUsersByOrganization = this.effect((organization$: Observable<Organization>) => organization$.pipe(
        switchMap(organization => this.provider.findByOrganization$(organization).pipe(
            map(users => users.map(user => ({
                ...user,
                organizations: [
                    ...user?.organizations ?? [],
                    organization
                ]
            }))),
            tapResponse(
                users => this.upsertMany(users),
                (error: HttpErrorResponse) => this.onError(error)
            )
        )),
    ));

    /** @ignore */
    private subs = new SubSink();

    protected override getEntityAdapter(): EntityAdapter<User, UserState> {
        return {
            storeName: 'UserStore',
            initialState: {ids: [], entities: {}, currentUserId: undefined},
            selectId: user => user.id,
        };
    }

    readonly selectUsersByOrganization$ = (organization: {id: IdType}) => this.select(
        this.selectAll$,
        users => users?.filter(user => user.organizations?.filter(org => org.id === organization.id).length)
    );

    constructor(
        protected override provider: UserProvider,
        private authStore: AuthStore,
        private accountPermissionStore: AccountPermissionStore
    ) {
        super(provider);

        this.subs.sink = authStore.selectCurrentUser$.pipe(
            tap(user => {
                if (user) {
                    this.upsertOne(user as User);
                }
                this.setCurrentUser(user as User);
            }),
        ).subscribe();

        this.subs.sink = accountPermissionStore.updated.pipe(
            switchMap(change => {
                const accountPermission = change.entities[0];

                return this.selectUserById$(accountPermission.account?.id ?? undefined).pipe(
                    take(1),
                    map(user => {
                        const index = user?.accountPermissions?.findIndex(value => value.id === accountPermission.id);
                        if (user !== undefined && user.accountPermissions !== undefined && index !== undefined && index >= 0) {
                            const permissions = [...user.accountPermissions];
                            permissions.splice(index, 1, accountPermission);

                            return {
                                ...user,
                                accountPermissions: permissions
                            } as User;
                        }

                        return undefined;
                    })
                );
            }),
            filter((user): user is User => user !== undefined),
            tap(user => this.upsertOne(user))
        ).subscribe();
    }

    public override onSuccess(change: EntitiesChange<User>): void {
        switch (change.type) {
            case 'post':
                this.#created.next(change);
                break;

            case 'patch':
                this.#updated.next(change);
                break;

            default: break;
        }
    }

    public override onError(error: HttpErrorResponse): void {
        this.#error.next(error);
    }

    override ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    // eslint-disable-next-line no-empty-function
    initialize(): void {}
}
