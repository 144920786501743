import {Component, Input} from '@angular/core';

import {ActionButtonComponent} from './action-button.component';

@Component({
    selector: 'ui-edit-button',
    templateUrl: './action-button.component.html',
    standalone: false
})
export class EditButtonComponent extends ActionButtonComponent {

    @Input() override color = 'primary';
    @Input() override icon = 'edit';

}
