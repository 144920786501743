import {ChangeDetectionStrategy, Component, Renderer2, inject, OnDestroy} from '@angular/core';
import {Router, NavigationEnd, NavigationSkipped} from '@angular/router';
import {ScrollingService} from '@ui/scrolling';
import {filter, tap} from 'rxjs/operators';
import {SubSink} from 'subsink';

import {DrawerService} from '../../services/drawer.service';

@Component({
    selector: 'drawer-drawer',
    template: `
    <div [ngClass]="{'drawer-preview':show, 'disable-drawer-preview':!show}">
        <router-outlet name="drawer" (activate)="activate()" (deactivate)="deactivate()"></router-outlet>
    </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class DrawerComponent implements OnDestroy {
    show = false;

    #drawerService = inject(DrawerService);
    #renderer = inject(Renderer2);
    #scrollingService = inject(ScrollingService);
    #router = inject(Router);
    #subs = new SubSink();

    constructor() {
        this.#subs.sink = this.#router.events.pipe(
            filter(event => event instanceof NavigationEnd || event instanceof NavigationSkipped),
            filter(({url}) => url.includes('drawer:')),
            tap(() => this.scrollToDrawerHeader())
        ).subscribe();
    }

    ngOnDestroy(): void {
        this.#subs.unsubscribe();
    }

    close(): void {
        this.#drawerService.closeDrawer();
    }

    back(): void {
        this.#drawerService.back();
    }

    activate(): void {
        this.show = true;
        this.#renderer.addClass(document.body, 'drawer-opened');
    }

    scrollToDrawerHeader(): void {
        // need to wait for the drawer to be rendered
        setTimeout(() => {
            const elem = document.querySelector('div.drawer-header');
            if (elem instanceof HTMLElement) {
                this.#scrollingService.scrollToElement(elem);
            }
        }, 249);
    }

    deactivate(): void {
        this.show = false;
        this.#renderer.removeClass(document.body, 'drawer-opened');
    }
}
