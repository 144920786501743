import {ChangeDetectionStrategy, Component, EventEmitter, Inject, OnDestroy, Output, inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Hotkeys} from '@configuration';
import {HotkeysService} from '@ngneat/hotkeys';
import {SubSink} from 'subsink';

import {DialogConfiguration} from '../../services/dialog.service';


@Component({
    selector: 'mgcn-dialog',
    templateUrl: './dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class DialogComponent implements OnDestroy {
    @Output() dialogDismissed: EventEmitter<void> = new EventEmitter<void>();
    @Output() dialogAccepted: EventEmitter<unknown> = new EventEmitter<unknown>();
    @Output() dialogAcceptedRedirect: EventEmitter<unknown> = new EventEmitter<unknown>();
    @Output() dialogAcceptedAndAdd: EventEmitter<unknown> = new EventEmitter<unknown>();

    private subsDialogComponent = new SubSink();

    hotkeysService = inject(HotkeysService);

    constructor(
        @Inject(MAT_DIALOG_DATA) public configuration: DialogConfiguration // eslint-disable-next-line no-empty-function
    ) {}

    addActionShortcuts(forCreation: boolean = false): void {
        const existingHotkeys = this.hotkeysService.getHotkeys();
        const indexSaveDialog = existingHotkeys.findIndex(hotkey => hotkey.keys === Hotkeys.SAVE_DIALOG);
        if (-1 === indexSaveDialog) {
            this.subsDialogComponent.sink = this.hotkeysService.addShortcut({
                keys: Hotkeys.SAVE_DIALOG,
                group: 'KEYMAP.GROUP.SAVE',
                description: 'KEYMAP.SAVE_DIALOG',
                allowIn: ['INPUT', 'SELECT', 'TEXTAREA']
            }).subscribe(() => this.handleValidationForm(false, false));
        }
        if (forCreation) {
            const indexSaveAndSeeDetail = existingHotkeys.findIndex(hotkey => hotkey.keys === Hotkeys.SAVE_AND_SEE_DETAIL);
            if (-1 === indexSaveAndSeeDetail) {
                this.subsDialogComponent.sink = this.hotkeysService.addShortcut({
                    keys: Hotkeys.SAVE_AND_SEE_DETAIL,
                    group: 'KEYMAP.GROUP.SAVE',
                    description: 'KEYMAP.SAVE_AND_SEE_DETAIL',
                    allowIn: ['INPUT', 'SELECT', 'TEXTAREA']
                }).subscribe(() => this.handleValidationForm(true, false));
            }

            const indexSaveAndAddAnother = existingHotkeys.findIndex(hotkey => hotkey.keys === Hotkeys.SAVE_AND_ADD_ANOTHER);
            if (-1 === indexSaveAndAddAnother) {
                this.subsDialogComponent.sink = this.hotkeysService.addShortcut({
                    keys: Hotkeys.SAVE_AND_ADD_ANOTHER,
                    group: 'KEYMAP.GROUP.SAVE',
                    description: 'KEYMAP.SAVE_AND_ADD_ANOTHER',
                    allowIn: ['INPUT', 'SELECT', 'TEXTAREA']
                }).subscribe(() => this.handleValidationForm(false, true));
            }
        }
    }

    removeActionShortcuts(): void {
        this.hotkeysService.removeShortcuts([Hotkeys.SAVE_DIALOG, Hotkeys.SAVE_AND_SEE_DETAIL, Hotkeys.SAVE_AND_ADD_ANOTHER]);
    }

    // eslint-disable-next-line no-empty-function
    handleValidationForm(redirect: boolean, another: boolean): void {}

    ngOnDestroy(): void {
        this.subsDialogComponent.unsubscribe();
    }
}
