import {Component, Input, OnDestroy, OnInit, forwardRef} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {AppComponent} from '@app-component/data';
import {Feeds, Mention, MentionConversionService, MentionStore} from '@mention/data';
import {Versionable} from '@versionable/data';
import CustomEditor from 'managician-ckeditor';
import {take, tap} from 'rxjs';
import {SubSink} from 'subsink';
import truncate from 'truncate-html';

@Component({
    selector: 'ckeditor-mentions',
    templateUrl: './ckeditor-mentions.component.html',
    styleUrls: ['./ckeditor-mentions.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CkeditorMentionsComponent),
            multi: true
        }
    ],
    standalone: false
})
export class CkeditorMentionsComponent implements OnInit, OnDestroy, ControlValueAccessor {

    initFeeds: Feeds[] = [];
    @Input() currentRequirement?: Versionable | AppComponent;
    @Input() mentions = {feeds: this.initFeeds};

    mentionsData$ = this.mentionStore.agglomerateAvailableMention$.pipe(
        tap(([actorMentions, dataModels, features, forms, funcReqs, nonFuncReqs, pages, useCases, tables, terms, applications, appComponents, informationSystems]) => {
            this.actorsMention = actorMentions.concat(applications).concat(appComponents).concat(informationSystems);
            this.requirementsMention = dataModels.concat(features, forms, funcReqs, nonFuncReqs, pages, useCases, tables);

            this.resetTermsMention();
            terms.forEach((wordMention: Mention) => {
                this.termsMention[wordMention.id?.charAt(0)]?.push(wordMention);
            });

            if (this.currentRequirement) {
                this.actorsMention = this.actorsMention.filter(actorMention => actorMention.entityId !== this.currentRequirement?.id || 'application' === actorMention.entity || 'applicationcomponent' === actorMention.entity);
                this.requirementsMention = this.requirementsMention.filter(requirementMention => requirementMention.entityId !== this.currentRequirement?.id);
            }
        })
    );

    value = '';
    requirementsMention: Mention[] = [];
    actorsMention: Mention[] = [];
    termsMention: {[key: string]: Mention[]} = {};
    Editor = CustomEditor;

    #subs = new SubSink();

    // eslint-disable-next-line no-empty-function
    onChange = (value: string) => {};

    constructor(
        public mentionStore: MentionStore,
        public mentionConversionService: MentionConversionService
        // eslint-disable-next-line no-empty-function
    ) {
    }

    ngOnInit(): void {
        this.loadFeeds();
    }

    /**
     * Constructs the mention feeds, including all the glossary terms feeds (one per letter and number).
     * Also calls addAllMentions() to bootstrap the observables (if no mentions have been passed as @Input).
     */
    private loadFeeds(): void {
        this.mentions = {feeds: []};
        this.resetTermsMention();
        this.addAllMentions();
    }

    addAllMentions(): void {
        this.#subs.sink = this.mentionsData$.subscribe(() => {
            this.mentions.feeds.push(
                {
                    marker: '@',
                    feed: this.getFeedActors.bind(this),
                    itemRenderer: this.customActorRenderer
                }
            );

            this.mentions.feeds.push(
                {
                    marker: '#',
                    feed: this.getFeedRequirements.bind(this),
                    itemRenderer: this.customRequirementRenderer
                }
            );
        });
    }

    customRequirementRenderer(item: Mention): HTMLSpanElement {
        const itemElement = document.createElement('span');

        itemElement.classList.add('custom-item');
        itemElement.textContent = `${item.text}`;

        const usernameElement = document.createElement('span');

        usernameElement.classList.add('custom-item-code');
        usernameElement.textContent = ' ' + item.code;

        itemElement.appendChild(usernameElement);

        return itemElement;
    }

    customTermsRenderer(item: any): HTMLSpanElement {
        const itemElement = document.createElement('span');

        itemElement.classList.add('custom-item');
        itemElement.textContent = `${item.id}`;

        if (item.description) {
            const descriptionElement = document.createElement('span');

            descriptionElement.classList.add('custom-item-description');
            descriptionElement.style.fontStyle = 'italic';
            descriptionElement.textContent = ' - ' + truncate(item.description, 6, {byWords: true, stripTags: true, decodeEntities: true});

            itemElement.appendChild(descriptionElement);
        }

        return itemElement;
    }

    customActorRenderer(item: Mention): HTMLSpanElement {
        const itemElement = document.createElement('span');

        itemElement.classList.add('custom-item');
        itemElement.textContent = `${item.text}`;

        return itemElement;
    }

    getFeedRequirements(queryText: string) {
        return this.requirementsMention
            // Filter out the full list of all items to only those matching the query text.
            .filter(value => value?.text?.toLowerCase().includes(queryText.toLowerCase()) ||
                value?.code?.toLowerCase().includes(queryText.toLowerCase()))
            // Return 10 items max - needed for generic queries when the list may contain hundreds of elements.
            .slice(0, 10);
    }

    getFeedActors(queryText: string) {
        return this.actorsMention
            // Filter out the full list of all items to only those matching the query text.
            .filter(value => value?.text?.toLowerCase().includes(queryText.toLowerCase()))
            // Return 10 items max - needed for generic queries when the list may contain hundreds of elements.
            .slice(0, 10);
    }

    getFeedTerms(firstLetter: string, queryText: string) {
        queryText = firstLetter + queryText;

        return this.termsMention[firstLetter]
            // Filter out the full list of all items to only those matching the query text.
            .filter(value => value?.text?.toLowerCase().includes(queryText.toLowerCase()))
            // Return 10 items max - needed for generic queries when the list may contain hundreds of elements.
            .slice(0, 10);
    }


    private resetTermsMention(): void {
        const allCharacter = 'abcdefghijklmnopqrstuvwxyzàáâãäåçèéêëìíîïñòóôõöùúûüýÿ';
        const allCharacterInArray = (allCharacter + allCharacter.toUpperCase() + '0123456789').split('');
        allCharacterInArray.forEach((character: string) => {
            this.termsMention[character] = [];
            this.mentions.feeds.push({
                marker: character,
                feed: this.getFeedTerms.bind(this, character),
                itemRenderer: this.customTermsRenderer
            });
        });
    }

    registerOnChange(fn: (value: string) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: string): void {
        // To remove ControlValueAccessor error
    }

    writeValue(obj: string): void {
        // When a versionable is a mention, and its name is updated, the mention in the edition form still the old value.
        this.mentionConversionService.convertDescriptionMention(obj).pipe(take(1)).subscribe(
            value => {
                obj = value;
            }
        );
        this.value = obj as string;
    }

    ngOnDestroy() {
        this.#subs.unsubscribe();
    }

}
